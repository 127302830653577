<template>
  <div class="mobile-container">
    <div class="section section-light-blue" style="text-align: center; margin-bottom: 0px;">
      <div class="header" style="display: flex; justify-content: flex-start; margin-bottom: 20px;">
        <img src="@/assets/logo.png" alt="Mima Logo" style="width: 50px; margin-right: 10px; display: inline-block;">
        <h1 style="margin-left: 3px;">Mima</h1>
      </div>
      <h2 style="text-align: center; font-size: 41px;">Personalized AI<br/>Job Hunting Assistant</h2>
      <p style="font-size: 19px; margin-top: 15px;text-align: center;">Your personal AI career coach is here 24/7 to guide you in landing your dream job that perfectly matches your interests and skills!</p>
      <!-- <img src="illustration.png" alt="Illustration"> -->
      <div class="reviews">
        <div class="review">
          <img src="../assets/homepage_student.png" alt="student" style="width: 74%;">
          <h3><strong style="font-size: 20px;">30</strong> students testers</h3>
          <h3 style="font-size: 12px;"><strong>from top university unions</strong></h3>
          <p style="text-align: center; font-size: 24px; font-family: 'Sukhumvit Set';">"Very useful!"</p>
        </div>
        <div class="review">
          <img src="../assets/homepage_professors.png" alt="professors" style="width: 60%;">
          <h3><strong style="font-size: 20px;">10</strong> professors</h3>
          <h3 style="font-size: 12px;"><strong>from top universities</strong></h3>
          <p style="text-align: center; font-size: 24px; font-family: 'Sukhumvit Set';">"Recommended"</p>
        </div>
      </div>
      <a v-if="userLoggedIn" @click="$router.push('/my-resume')" class="button">Try it now</a>
      <a v-else @click="openLogin" class="button">Try it now</a>
      <div class="section" style="text-align: center;">
        <img src="../assets/homepage_youtube.png" alt="Video Thumbnail" @click="openLink" style="width: 90%; margin-top: 20px; cursor: pointer;">
        <!-- <p>Mima: Your AI Job Hunting Assistant</p> -->
      </div>
    </div>
    <div class="section section-light-gray" style="text-align: center;">
      <h2>AI Resume Scoring</h2>
      <p>Get instant feedback on your resume,and know your strenth! By analyzing industry-specific keywords and resume formatting, we help you understand how well your resume aligns with industry standards and job descriptions, empowering you to make actionable steps to increase your chances of catching the attention of hiring managers.</p>
      <img src="../assets/homepage_know_your_strength.png" alt="AI Resume Scoring">
      <a v-if="userLoggedIn" @click="$router.push('/my-resume')" class="button black">Scoring My Resume</a>
      <a v-else @click="openLogin" class="button black">Scoring My Resume</a>
    </div>
    <div class="section section-yellow" style="text-align: center;">
      <h2>AI Resume Polishing</h2>
      <p style="margin-bottom: 5px;">Become the ideal candidate of your dream job!</p>
      <ul>
        <li>Personalized refinement that highlights your unique strengths and achievements.</li>
        <li>Industry-specific language to enhance credibility.</li>
        <li>Professionism through industry-standard resume template.</li>
      </ul>
      <p>We specialize in making your resume stand out in a competitive job market, ensuring it captures the interest of hiring managers.</p>
      <img src="../assets/homepage_polished_resume.jpeg" alt="Resume Polishing">
      <a v-if="userLoggedIn" @click="$router.push('/my-resume')" class="button black">Polishing My Resume</a>
      <a v-else @click="openLogin" class="button black">Polishing My Resume</a>
    </div>
    <div class="section section-light-gray" style="text-align: center;">
      <h2>AI Job Match</h2>
      <p>Take the guesswork out of your job search!<br/>
        MIMA will match you with the perfect career path based on your personality, interests, and background. It will recommend the positions that suit you best and have the highest success rate for your applications. 
        <br/>Come and try MIMA’s matching feature now!</p>
      <img src="../assets/homepage_job_match.jpg" alt="AI Job Match">
      <a v-if="userLoggedIn" @click="$router.push('/my-resume')" class="button black">Start Matching</a>
      <a v-else @click="openLogin" class="button black">Start Matching</a>
    </div>
    <div class="section section-white" style="text-align: center;">
      <h2>Well-developed Network</h2>
      <p>Join us! Connect with senior students and industry professionals now!</p>
      <img src="../assets/homepage_inner_connection.jpg" alt="Well-developed Network">
      <a href="#" class="button join">Join Them</a>
    </div>
    <div class="footer" style="text-align: center;">
      <img src="@/assets/logo.png" alt="Footer Logo" style="width: 10%; margin-top: 30px; margin-bottom: 0px; display: block; margin-left: auto; margin-right: auto;">
      <h1 style="margin-bottom: 0px;">Mima</h1>
      <p style="margin-top: 0px;">2024 Mima Inc. All rights reserved.</p>
    </div>
  </div>
  <LoginComponent :visible="isLoginVisible" @close="closeLogin" />
</template>
<script>
import LoginComponent from '@/components/LoginComponent.vue';
import { mapState } from 'vuex';
// import ResumeChooseComponent from './ResumeChooseComponent.vue';
export default {
    name: "MobileView",
    components: { LoginComponent},
    data() {
      return {
        isLoginVisible: false,
        isResumeChooseVisible: false,
      };
    },
    computed: {
      ...mapState(["userLoggedIn"]),
    },
    methods: {
      // 打开登录弹窗
      openLogin() {
        this.isLoginVisible = true;
      },
      // 关闭登录弹窗
      closeLogin() {
        this.isLoginVisible = false;
      },
      // 显示 ResumeChooseComponent
      showResumeChoose() {
        this.isResumeChooseVisible = true;
      },
      // 关闭 ResumeChooseComponent
      closeResumeChoose() {
        this.isResumeChooseVisible = false;
      },
      alertTBA() {
        alert('This part is not available now. Please stay tuned!')
      },
      openLink() {
        window.open("https://youtu.be/xKy3pyC4qe0?si=LPD1TZFCg13UW3IX", "_blank");
      }
    },
  };
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-family: Arial, sans-serif;
}


.mobile-container {
  /* max-width: 800px; */
  /* margin: 0 auto; */
  margin-bottom: 20px;
  padding: 0px;
}
.header, .footer {
  text-align: left;
  margin-bottom: 20px;
}
.header img, .footer img {
  width: 50px;
  margin-bottom: 10px;
  display: block;
  margin-left: 0;
}
.header h1, .footer h1 {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  font-family: "Arial";
}
.section {
  /* text-align: center; */
  margin: 0 0;
  width: 100%;
}
.section h2 {
  color: #000002;
  font-size: 36px;
  font-weight: 900;
  line-height: 40px;
  text-align: left;
  font-family: "Arial";
}
.section p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
  text-align: left;
  font-family: "Arial";
}
.section li {
  font-size: 16px;
  color: #666;
  margin-bottom: 5px;
  text-align: left;
  font-family: "Arial";
}
.section img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.section-light-gray {
  background-color: #f2f3f5;
  padding: 40px;
  width: 100%;
}
.section-white {
  background-color: #ffffff;
  padding: 40px;
  width: 100%;
}
.section-yellow {
  background-color: #e0cd6c;
  padding: 40px;
  width: 100%;
}
.section-light-blue {
  /* background-color: #e3f2fd; */
  padding: 20px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-image: url('../assets/home_background.png');
  background-size: cover;
  background-position: center;
}
.section .reviews {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.review {
  width: 50%;
  font-size: 14px;
  color: #333;
}
.review h3 {
  font-size: 14px;
  /* font-weight: bold; */
  color: #333;
  margin: 0;
}
.review strong {
  font-weight: bold;
}
.footer p {
  font-size: 14px;
  color: #666;
  margin-top: 20px;
  margin-bottom: 0;
}
.button {
  width: 240px;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-family: "Arial";
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 10px;
  cursor: pointer;
}
.button.black {
  cursor: pointer;
  top: 1134px;
  left: 100px;
  width: 240px;
  height: 48px;
  padding: 0px;
  border: 0;
  box-sizing: border-box;
  border-radius: 22px;
  background-color: #000000;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  outline: none;
  padding-top: 5px;
}
.button.join {
  cursor: pointer;
  top: 2639px;
  left: 99px;
  width: 240px;
  height: 48px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 22px;
  background-color: #1f3276;
  color: #f5ffff;
  font-size: 18px;
  /* font-family: "Roboto"; */
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  outline: none;
  padding-top: 5px;
}
</style>