<template>
  <div style="background-color: #eeeeee;">
    <img
      src="./assets/main_page.jpg"
      alt="Main Page"
      class="cuhk-img"
      @contextmenu.prevent
      :draggable="false"
    >
    <img
      src="./assets/blank_page.jpg"
      alt="Blank Page"
      class="cuhk-img"
      @contextmenu.prevent
      :draggable="false"
    >
    <img
      src="./assets/button.jpg"
      alt="Button Page"
      class="cuhk-img"
      @click="openRegister"
      style="cursor: pointer;"
      @contextmenu.prevent
      :draggable="false"
    >
    <img
      src="./assets/blank_page.jpg"
      alt="Blank Page"
      class="cuhk-img"
      @contextmenu.prevent
      :draggable="false"
    >
  </div>

  <!-- 注册弹窗 -->
  <RegistrationComponent
    :visible="isRegisterVisible"
    :discountId="discountId"
    @close="closeRegister"
  />
</template>

<script>
import RegistrationComponent from '@/components/RegistrationComponent.vue';

export default {
  name: "CUHKView",
  components: {
    RegistrationComponent,
  },
  data() {
    return {
      isRegisterVisible: false,
      discountId: "CUHK_250227",
    };
  },
  methods: {
    openRegister() {
      this.isRegisterVisible = true;
    },
    // 关闭注册弹窗
    closeRegister() {
      this.isRegisterVisible = false;
    },
  }
}
</script>

<style scoped>
.cuhk-img {
  width: 100%;
  margin: 0;
  border: 0;
  padding: 0;
}
</style>
