<template>
  <div class="header">
    <div class="section section-left">
      <img src="@/assets/logo.svg" alt="MIMA Logo" class="logo" />
      <span class="mima-text">MIMA</span>
    </div>
    <div class="section section-center">
      <router-link to="/" class="nav-item">Home</router-link>
      <router-link to="/pricing" class="nav-item">Pricing</router-link>
      <router-link v-if="userLoggedIn" to="/my-resume" class="nav-item">
        My Resume
      </router-link>
      <a v-else @click="openLogin" class="nav-item">My Resume</a>
      <router-link to="/about" class="nav-item">About</router-link>
    </div>
    <div class="section section-right">
      <template v-if="userLoggedIn">
        <span class="greeting">Hey, welcome!</span>
        <button class="btn" @click="handleLogout">Log out</button>
      </template>
      <template v-else>
        <button class="btn" @click="openLogin">Sign in</button>
        <button class="btn" @click="openRegister">Join now</button>
      </template>
    </div>

    <!-- 登录弹窗 -->
    <LoginComponent :visible="isLoginVisible" @close="closeLogin" />
    <!-- 注册弹窗 -->
    <RegistrationComponent
      :visible="isRegisterVisible"
      @close="closeRegister"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import LoginComponent from "./LoginComponent.vue";
import RegistrationComponent from "@/components/RegistrationComponent.vue";

export default {
  name: "HeaderComponent",
  components: {
    LoginComponent,
    RegistrationComponent,
  },
  data() {
    return {
      isLoginVisible: false,
      isRegisterVisible: false,
    };
  },
  computed: {
    ...mapState(["userLoggedIn"]),
  },
  methods: {
    ...mapActions(["logoutUser"]),
    handleLogout() {
      // 先跳转到 Home 页面
      this.$router.push({ name: "Home" }).then(() => {
        // 跳转完成后再执行 logout 操作
        this.logoutUser();
      });
    },
    openLogin() {
      this.isLoginVisible = true;
    },
    // 关闭登录弹窗
    closeLogin() {
      this.isLoginVisible = false;
    },
    // 打开注册弹窗
    openRegister() {
      this.isRegisterVisible = true;
    },
    // 关闭注册弹窗
    closeRegister() {
      this.isRegisterVisible = false;
    },
    handleScroll() {
      const header = document.querySelector('.header');
      if (window.scrollY > 50) {
        header.classList.add('scrolled');
      } else {
        header.classList.remove('scrolled');
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  background-color: #ffffff;
  user-select: none;
  border-bottom: 1px solid #ccc;
  white-space: nowrap;
  z-index: 10000;
}

.section {
  display: flex;
  align-items: center;
}

.section-left {
  justify-content: flex-start;
}

.beta-text {
  font-size: 15px;
  font-style: italic;
  color: #1d2e79;
  position: relative;
  top: -10px;
  left: -7px;
}

.logo {
  height: 55px;
  margin-right: 10px;
}

.mima-text {
  font-weight: bold;
  color: #1d2e79;
  font-size: 28px;
  margin-right: 5px;
  margin-top: 3px;
  position: relative;
}

.section-center {
  justify-content: center;
  flex-grow: 1;
}

.nav-item {
  margin: 0 40px;
  color: #000000;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  transition: all 0.3s ease;
  text-decoration: none;
}

.nav-item:hover {
  text-decoration: none;
}

.nav-item::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #1d2e79;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.nav-item:hover::after {
  width: 100%;
}

.router-link-active::after {
  width: 100%;
}

.section-right {
  justify-content: flex-end;
}

.greeting {
  margin-right: 15px;
  color: #1d2e79;
  font-size: 18px;
}

.btn {
  background-color: #1d2e79;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
  transition: background-color 0.1s, box-shadow 0.3s;
}

.btn:hover {
  background-color: #2a3daa;
}

.btn:active {
  background-color: #1d2e79;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1087px) {
  .nav-item {
    margin: 0 30px;
  }
}

@media (max-width: 1007px) {
  .nav-item {
    margin: 0 20px;
  }
}

@media (max-width: 927px) {
  .header {
    align-items: center;
    flex-wrap: wrap;
  }

  .section-left,
  .section-right {
    order: 1;
    margin-bottom: 10px;
  }

  .section-center {
    order: 2;
    align-items: center;
    min-width: 500px;
    max-width: 700px;
    margin: auto;
  }

  .nav-item {
    margin: auto;
  }
}
</style>
